<template>
  <!-- 管理文章图片 批量上传图片按钮 分为当前文章图片 / 已上传的图片两种 -->
  <CanMove
    title="图片管理"
    position="top: 100px; left: calc(100% - 500px - 10px); z-index: 1999;"
    width="520"
    height="540"
    :visible="showImageManager"
    @close="handleClose"
  >
    <div class="content">
      <div class="menu">
        <div :class="['menu-item', selectNum === 1 ? 'isClick' : '']" @click="selectNum = 1">文章图片</div>
        <div :class="['menu-item', selectNum === 3 ? 'isClick' : '']" @click="selectNum = 3">文章封面</div>
        <div :class="['menu-item', selectNum === 2 ? 'isClick' : '']" @click="selectNum = 2">服务器图片</div>
      </div>
      <div class="image-wrapper">
        <div :class="['image-wrapper-1', selectNum === 1 ? 'image-wrapper-show' : '']">
          <min-image
            class="image-article"
            :image="item"
            v-for="(item, index) in articleImageList"
            @onload="handleOnloadArticle"
            :key="item.id"
          ></min-image>
        </div>
        <div
          :class="['image-wrapper-2', selectNum === 2 ? 'image-wrapper-show' : '']"
          style="overflow: auto"
          v-infinite-scroll="load"
          :infinite-scroll-distance="50"
        >
          <min-image
            class="image-server"
            :image="item"
            v-for="(item, index) in serverImageList"
            :key="item.id"
            @onload="handleOnloadServer(index)"
            :index="index"
          ></min-image>
        </div>

        <div :class="['image-wrapper-3', selectNum === 3 ? 'image-wrapper-show' : '']">
          <div class="article-cover">
            <el-image
              :src="mediaIp + coverUrl"
              v-if="coverUrl"
              class="article-cover-img"
              :preview-src-list="[mediaIp + coverUrl]"
            ></el-image>
            <div class="article-cover-tips" v-else>文章封面等比例展示区</div>
          </div>

          <el-button @click="handleShowCut" class="uploadCover" type="primary">上传封面</el-button>

          <CutImageComponent
            :modal="false"
            :visible.sync="showCut"
            :option="options"
            :cutwidth="600"
            :cutheigth="450"
            @close="showCut = false"
            @submit="handleSubmit"
          ></CutImageComponent>
        </div>
      </div>

      <div class="add-btn-wrapper" ref="upload">
        <el-upload
          :action="api + '/admin/uploadImage'"
          multiple
          :file-list="fileList"
          accept="image/*"
          :headers="headers"
          :data="fileQuery"
          :on-success="handleSuccess"
          :show-file-list="true"
          :on-progress="handleProgress"
          ref="uploadRef"
        >
          <el-button size="small" type="primary">上传新图片</el-button>

          <span slot="tip" class="add-btn-tips">( 可上传多张图片到服务器,可以复制使用 )</span>
        </el-upload>
      </div>
    </div>
  </CanMove>
</template>

<script>
import { mediaIp, api, cuterDefualtOptions, formDataConfig } from '@/global/global'
import CanMove from '@/components/CanMoveComponent.vue'
import MinImage from '@/components/MinImageComponent.vue'
import CutImageComponent from './CutImageComponent.vue'

export default {
  props: {
    visible: Boolean,
    articleImageList: Array,
    // 封面url
    coverUrl: {
      type: String,
      default: ''
    }
  },

  components: {
    CanMove,
    MinImage,
    CutImageComponent
  },

  watch: {
    visible(n) {
      this.showImageManager = n
    },
    coverUrl(n) {
      if (n) {
        this.selectNum = 2
      } else {
        this.selectNum = 3
      }
    }
  },
  data() {
    return {
      showImageManager: true,
      selectNum: 2,
      pageNum: 1,
      size: 10,
      onloadedServerCount: 0,
      onloadedArticleCount: 0,
      errorImageList: [],
      serverImageList: [],
      fileList: [],
      mediaIp: mediaIp,
      headers: {
        authorization: window.sessionStorage.getItem('token')
      },
      fileQuery: {
        username: 'superlwb',
        kind: 'article'
      },
      // 用于判断当前状态是否为上传图片状态，相当于同步锁
      lock: {
        uploadCount: 0,
        willUploadCount: 0
      },

      showCut: false,
      options: cuterDefualtOptions,

      // 切换模式，切换api
      api: process.env.NODE_ENV === 'production' ? api : ''
    }
  },

  methods: {
    handleClose() {
      this.$emit('close')
    },
    getData() {
      this.getServerData()
    },
    async getServerData() {
      const { data: res } = await this.$http.get('/admin/getImageByKindList', {
        params: {
          kindList: ['article', 'articleCover'],
          pageNum: this.pageNum,
          size: this.size
        }
      })

      // console.log(res)

      if (res.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + res.status + ' error：' + res.message
        })
        return
      }

      // 如果为第一页时，直接把结果赋给serverlist
      if (this.pageNum === 1) this.serverImageList = res.data
      else this.serverImageList.push(...res.data)
    },
    async handleSuccess(e, file, fileList) {
      // console.log(e)
      // console.log(this.$refs.uploadRef)

      this.lock.uploadCount++
      if (e.status != 200) {
        this.$notify.error({
          title: '错误',
          message: 'status：' + e.status + ' error：' + e.message
        })
        return
      }

      this.$message({
        message: e.message,
        type: 'success'
      })

      if (this.lock.willUploadCount === this.lock.uploadCount) {
        // 如果当前状态是上传新图片状态时。将其状态重置
        this.serverImageList = []
        this.onloadedServerCount = 0
        this.pageNum = 1
        // 重置锁
        this.lock = {
          uploadCount: 0,
          willUploadCount: 0
        }
        this.$refs.uploadRef.clearFiles()

        this.getServerData()
        return
      }
    },
    handleProgress(e, file, fileList) {
      this.lock.willUploadCount = fileList.length
    },
    handleChildComponentLoaded(index, images) {
      const margin = 5
      const width = 155

      const pos = index + 1
      let currImage = images[index]
      if (pos <= 3) {
        if (pos % 3 == 1) {
          currImage.style.left = Number.parseFloat(0 + margin) + 'px'
        } else if (pos % 3 == 2) {
          currImage.style.left = Number.parseFloat(width + margin) + 'px'
        } else {
          currImage.style.left = Number.parseFloat(width * 2 + margin) + 'px'
        }
      } else {
        // 获取他上面的元素高度
        const up = index - 3
        let upImage = images[up]
        const upHeight = window.getComputedStyle(upImage).getPropertyValue('height').split('px')[0]

        const upTop = window.getComputedStyle(upImage).getPropertyValue('top').split('px')[0]

        const topNum = Number.parseFloat(upTop) + Number.parseFloat(upHeight) + margin
        const top = topNum + 'px'
        currImage.style.top = top
        // console.log(top)
        if (pos % 3 == 1) {
          currImage.style.left = Number.parseFloat(0 + margin) + 'px'
        } else if (pos % 3 == 2) {
          currImage.style.left = Number.parseFloat(width + margin) + 'px'
        } else {
          currImage.style.left = Number.parseFloat(width * 2 + margin) + 'px'
        }
      }
    },
    // 重置样式的
    async handleOnloadServer(index) {
      this.onloadedServerCount++

      // 处理加载失败的图片
      setTimeout(() => {
        if (this.onloadedServerCount === this.serverImageList.length) {
          this.setStyleByClass('.image-server')
        }
      }, 100)
    },

    async load() {
      if (this.lock.willUploadCount != this.lock.uploadCount) {
        // 如果当前状态是上传新图片状态时。将其状态重置
        return
      }

      // 如果所有图片加载完毕时
      if (this.onloadedServerCount === this.serverImageList.length) {
        this.pageNum++
        this.getServerData()
      }
    },
    async handleOnloadArticle() {
      this.onloadedArticleCount++
      if (this.onloadedArticleCount === this.articleImageList.length) {
        this.setStyleByClass('.image-article')
      }
    },
    setStyleByClass(className) {
      let images = document.querySelectorAll(className)
      for (let i = 0; i < images.length; i++) {
        this.handleChildComponentLoaded(i, images)
      }
    },
    // 打开上传封面的图片裁剪器
    handleShowCut() {
      this.showCut = true
      this.options.autoCropWidth = 400
      this.options.autoCropHeight = 300
      this.options.fixedNumber = [4, 3]
      // this.options.infoTrue = true
    },
    handleSubmit(e) {
      this.showCut = false
      this.$emit('submitCover', e)
    }
  },
  mounted() {
    this.getData()
  },
  created() {},
  updated() {
    // 每次关闭查看都要重置这个数量，不然会无限叠加
    if (!this.showImageManager) {
      this.onloadedArticleCount = 0
      this.onloadedServerCount = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  // background-color: aqua;

  .menu {
    width: 100%;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 5px;
    box-sizing: border-box;

    .menu-item {
      width: 100px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      // background-color: aquamarine;
      cursor: pointer;
      border-bottom: #96989c 1px solid;
    }

    .menu-item:first-child {
      margin-right: 10px;
    }

    .menu-item:hover {
      background-color: rgba($color: #000000, $alpha: 0.2);
    }

    .isClick {
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
  }

  .image-wrapper {
    width: 100%;
    // background-color: aquamarine;
    height: 400px;
    overflow: hidden;
    position: relative;

    .image-wrapper-1,
    .image-wrapper-2,
    .image-wrapper-3 {
      width: 100%;
      height: 100%;
      position: absolute;
      padding: 5px;
      box-sizing: border-box;
      transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      overflow: auto;
      // column-count: 3;
    }

    // 文章图片
    .image-wrapper-1 {
      // background-color: red;
      left: -100%;
    }

    // 服务器图片
    .image-wrapper-2 {
      left: 100%;
    }

    // 文章封面
    .image-wrapper-3 {
      top: 100%;
      // background-color: aquamarine;

      .article-cover {
        width: 400px;
        height: 300px;
        margin: 0 auto;
        // background-color: brown;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        overflow: hidden;

        border: 5px dashed rgba($color: #000000, $alpha: 0.1);
        color: rgba($color: #000000, $alpha: 0.3);

        .article-cover-img {
          width: 100%;
          height: 100%;
        }
      }

      .uploadCover {
        display: block;
        margin: 10px auto;
      }
    }

    .image-wrapper-show {
      left: 0%;
      top: 0%;
    }
  }

  .add-btn-wrapper {
    margin-top: 15px;

    .add-btn-tips {
      font-size: 10px;
      font-weight: 900;
      margin-left: 10px;
      color: rgba($color: #000000, $alpha: 0.4);
    }
  }
}
</style>
